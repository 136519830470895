<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
        <v-row>
          <v-col class="pb-1 pr-0" cols="1">
            <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
          </v-col>
          <v-col class="py-0 pl-0" cols="11">
            <PageHeader :title="title" />
          </v-col>
        </v-row>
        <v-data-table
          :loading="loadingTable"
          :headers="headers"
          :items="procesosImportacion"
          class="elevation-1"
          :search="search"
          item-key="procesoImportacionId"
        >
          <template v-slot:[`item.anulado`]="{ item }">
            <v-icon v-if="item.anulado" small color="primary">
              {{ checkIcon }}
            </v-icon>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="4" align="end">
                  <v-btn
                    color="primary"
                    class="to-right"
                    aria-label="Anular proceso"
                    @click="toggleModalNuevoProcesoImportacion()"
                  >
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  :disabled="item.fecAnula !== null"
                  @click="seeProceso(item.procId)"
                >
                  {{ seeIcon }}
                </v-icon>
              </template>
              <span>Ver detalle</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  :disabled="item.fecAnula !== null"
                  @click="anularProceso(item.procId)"
                >
                  {{ anularIcon }}
                </v-icon>
              </template>
              <span>Anular proceso</span>
            </v-tooltip>
          </template></v-data-table
        >
        <v-dialog
          v-model="modalNuevoProcesoImportacion"
          v-if="modalNuevoProcesoImportacion"
          @keydown.esc="toggleModalNuevoProcesoImportacion"
          max-width="60%"
          persistent
        >
          <NuevoProcesoImportacionBonifRecargo
            @toggleModalProcesoImportacionAfiliadoEmpresa="
              toggleModalNuevoProcesoImportacion
            "
          ></NuevoProcesoImportacionBonifRecargo>
        </v-dialog>
        <v-dialog
          v-model="openModalSeeProcess"
          v-if="openModalSeeProcess"
          @keydown.esc="openModalSeeProcess = false"
          max-width="80%"
          persistent
        >
          <v-card :loading="loadingProcesosToSee">
            <v-container class="p-0 pr-3">
              <v-card-title class="primary--text py-0 pl-1">
                <v-col>Datos del proceso: {{ idToSee }}</v-col>
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="headersRegistros"
                  :items="itemsProcessToSee"
                  class="elevation-1 custom-data-table"
                >
                  <template v-slot:[`item.empresaPorDefectoBool`]="{ item }">
                    <v-icon
                      v-if="item.empresaPorDefectoBool"
                      small
                      color="primary"
                    >
                      {{ checkIcon }}
                    </v-icon>
                  </template>
                  <template v-slot:[`item.sucursal`]="{ item }">
                    <span style="font-size: 12px"> {{ item.sucursal }}</span>
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions class="pr-4">
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="itemsProcessToSee.length == 0"
                  color="primary"
                  outlined
                  @click="exportDataToSee"
                  class="to-right fontsize"
                >
                  Exportar detalle
                </v-btn>
                <v-btn
                  outlined
                  @click="
                    (openModalSeeProcess = false), (itemsProcessToSee = [])
                  "
                >
                  Cerrar
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-dialog>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-0 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
      <DeleteDialog
        :titleProp="titleDelete"
        :maxWidth="'35%'"
        :confirmButtonText="'Anular'"
        :openDelete.sync="showAnularModal"
        :isLoading="isLoadingAnular"
        @onDeleteItem="confirmAnularProceso()"
      />
    </v-row>
  </v-container>
</template>

<script>
import GoBackBtn from "@/components/shared/GoBackBtn";
import DeleteDialog from "@/components/shared/DeleteDialog";
import enums from "@/utils/enums/index.js";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import NuevoProcesoImportacionBonifRecargo from "@/components/modules/afiliaciones/afiliados/procesos/NuevoProcesoImportacionAfiliadoEmpresa.vue";
import helpersExcel from "@/utils/helpers/importExportExcel";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "ProcesoImportacionRelacionAfiliadoEmpresa",
  components: {
    PageHeader,
    DeleteDialog,
    Ayuda,
    GoBackBtn,
    NuevoProcesoImportacionBonifRecargo,
  },
  data() {
    return {
      routeToGo: "ProcesosAfiliados",
      title: "Procesos de importación de relación afiliado - empresa",
      search: "",
      titleDelete: "¿Desea anular el proceso?",
      searchIcon: enums.icons.SEARCH,
      checkIcon: enums.icons.CHECK_OUTLINE,
      optionCode: enums.webSiteOptions.RELACION_AFILIADO_EMPRESA,
      anularIcon: enums.icons.CIRCLE_NONE,
      seeIcon: enums.icons.SEE,
      procesosImportacion: [],
      showHelp: false,
      showExpand: false,
      isLoadingAnular: false,
      itemsProcessToSee: [],
      headersRegistros: [
        {
          text: "CUIL",
          sortable: false,
          align: "left",
          value: "cuil",
        },
        {
          text: "Tipo documento",
          sortable: false,
          align: "left",
          value: "tipoDoc",
        },
        {
          text: "Número documento",
          sortable: false,
          align: "left",
          value: "nroDoc",
        },
        {
          text: "CUIT empresa",
          sortable: false,
          align: "left",
          value: "cuitEmpresa",
        },
        {
          text: "Sueldo",
          sortable: false,
          align: "left",
          value: "sueldo",
        },
        {
          text: "Aporte",
          sortable: false,
          align: "left",
          value: "aporte",
        },
        {
          text: "Contribución",
          sortable: false,
          align: "left",
          value: "contribucion",
        },
        {
          text: "Vigencia desde",
          sortable: false,
          align: "left",
          value: "fecha",
        },
        {
          text: "Vigencia hasta",
          sortable: false,
          align: "left",
          value: "fechaBaja",
        },
        {
          text: "Sucursal",
          sortable: false,
          align: "left",
          value: "sucursal",
        },
        {
          text: "Empresa por defecto",
          sortable: false,
          align: "left",
          value: "empresaPorDefectoBool",
        },
      ],
      headers: [
        {
          text: "ID Proceso",
          align: "start",
          value: "procId",
          sortable: false,
        },
        {
          text: "Fecha alta",
          align: "start",
          value: "fecAlta",
          sortable: false,
        },
        {
          text: "Usuario alta",
          align: "start",
          value: "usuAlta",
          sortable: false,
        },
        {
          text: "Nombre archivo",
          align: "start",
          value: "rutaArchivo",
          sortable: false,
        },
        {
          text: "Fecha anulado",
          align: "start",
          value: "fecAnula",
          sortable: false,
        },
        {
          text: "Usuario anulado",
          align: "start",
          value: "usuAnula",
          sortable: false,
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
      ],
      procesoImportacionId: null,
      modalNuevoProcesoImportacion: false,
      loadingTable: false,
      idToAnular: null,
      showAnularModal: false,
      openModalSeeProcess: false,
      loadingProcessToSee: false,
      idToSee: null,
    };
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.loadTipoProcesosRelacionAfiliadoEmpresa();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getProcesosImportacionRelacionAfiliadoEmpresa:
        "procesosAfiliado/getProcesosImportacionRelacionAfiliadoEmpresa",
      getRelacionAfiEmpresaByProcess:
        "procesosAfiliado/getRelacionAfiEmpresaByProcess",
      anularProcesoImportacionAfiliadoEmpresa:
        "procesosAfiliado/anularProcesoImportacionAfiliadoEmpresa",
    }),
    async loadTipoProcesosRelacionAfiliadoEmpresa() {
      this.loadingTable = true;
      try {
        this.procesosImportacion =
          await this.getProcesosImportacionRelacionAfiliadoEmpresa();
      } catch {}
      this.loadingTable = false;
    },
    anularProceso(id) {
      this.idToAnular = id;
      this.showAnularModal = true;
    },
    seeProceso(id) {
      this.openModalSeeProcess = true;
      this.loadingProcessToSee = true;
      this.idToSee = id;
      this.loadSeeProcess();
    },
    async loadSeeProcess() {
      this.loadingProcesosToSee = true;
      const res = await this.getRelacionAfiEmpresaByProcess(this.idToSee);
      if (res) this.itemsProcessToSee = res;
      else {
        this.setAlert({
          type: "warning",
          message: "No se encontraron datos del procesos.",
        });
      }
      this.loadingProcesosToSee = false;
    },
    exportDataToSee() {
      let result = [];
      this.itemsProcessToSee.forEach((x) =>
        result.push({
          ["CUIL"]: x.cuil?.toString(),
          ["Tipo documento"]: x.tipoDoc?.toString(),
          ["Número documento"]: x.nroDoc?.toString(),
          ["CUIT empresa"]: x.cuitEmpresa?.toString(),
          ["Sueldo"]: x.sueldo?.toString(),
          ["Aporte"]: x.aporte?.toString(),
          ["Contribucion"]: x.contribucion?.toString(),
          ["Vigencia desde"]: x.fecha?.toString(),
          ["Vigencia hasta"]: x.fechaBaja?.toString(),
          ["Sucursal"]: x.sucursal?.toString(),
          ["Empresa por defecto"]: x.empresaPorDefectoBool ? "SI" : "NO",
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado",
      };
      helpersExcel.excelExport(
        formato,
        `Detalle proceso ${this.idToSee.toString()}`
      );
    },
    async confirmAnularProceso() {
      this.isLoadingAnular = true;
      try {
        const response = await this.anularProcesoImportacionAfiliadoEmpresa(
          this.idToAnular
        );
        if (response.status === 200) {
          if (response.data.data)
            this.setAlert({ type: "success", message: "Anulado con éxito." });
          else
            this.setAlert({ type: "warning", message: "No se pudo anular." });
        } else
          this.setAlert({ type: "warning", message: "No se pudo anular." });
      } catch {}
      this.isLoadingAnular = false;
      this.showAnularModal = false;
      this.loadTipoProcesosRelacionAfiliadoEmpresa();
    },
    toggleModalNuevoProcesoImportacion() {
      this.modalNuevoProcesoImportacion = !this.modalNuevoProcesoImportacion;
      this.loadTipoProcesosRelacionAfiliadoEmpresa();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
  },
};
</script>

<style scoped>
.custom-data-table >>> .v-data-table-header th,
.custom-data-table >>> .v-data-table__divider,
.custom-data-table >>> .v-data-table__wrapper td,
.custom-data-table >>> .v-data-table__mobile-table-row {
  padding: 4px 8px;
  margin: 0;
}
</style>