var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0 pt-5 pb-0",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('v-row',[_c('v-col',{staticClass:"pb-1 pr-0",attrs:{"cols":"1"}},[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}})],1),_c('v-col',{staticClass:"py-0 pl-0",attrs:{"cols":"11"}},[_c('PageHeader',{attrs:{"title":_vm.title}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loadingTable,"headers":_vm.headers,"items":_vm.procesosImportacion,"search":_vm.search,"item-key":"procesoImportacionId"},scopedSlots:_vm._u([{key:"item.anulado",fn:function(ref){
var item = ref.item;
return [(item.anulado)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.checkIcon)+" ")]):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"4","align":"end"}},[_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary","aria-label":"Anular proceso"},on:{"click":function($event){return _vm.toggleModalNuevoProcesoImportacion()}}},[_vm._v(" Nuevo ")])],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20","disabled":item.fecAnula !== null},on:{"click":function($event){return _vm.seeProceso(item.procId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.seeIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Ver detalle")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20","disabled":item.fecAnula !== null},on:{"click":function($event){return _vm.anularProceso(item.procId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.anularIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Anular proceso")])])]}}],null,true)}),(_vm.modalNuevoProcesoImportacion)?_c('v-dialog',{attrs:{"max-width":"60%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.toggleModalNuevoProcesoImportacion($event)}},model:{value:(_vm.modalNuevoProcesoImportacion),callback:function ($$v) {_vm.modalNuevoProcesoImportacion=$$v},expression:"modalNuevoProcesoImportacion"}},[_c('NuevoProcesoImportacionBonifRecargo',{on:{"toggleModalProcesoImportacionAfiliadoEmpresa":_vm.toggleModalNuevoProcesoImportacion}})],1):_vm._e(),(_vm.openModalSeeProcess)?_c('v-dialog',{attrs:{"max-width":"80%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.openModalSeeProcess = false}},model:{value:(_vm.openModalSeeProcess),callback:function ($$v) {_vm.openModalSeeProcess=$$v},expression:"openModalSeeProcess"}},[_c('v-card',{attrs:{"loading":_vm.loadingProcesosToSee}},[_c('v-container',{staticClass:"p-0 pr-3"},[_c('v-card-title',{staticClass:"primary--text py-0 pl-1"},[_c('v-col',[_vm._v("Datos del proceso: "+_vm._s(_vm.idToSee))])],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 custom-data-table",attrs:{"headers":_vm.headersRegistros,"items":_vm.itemsProcessToSee},scopedSlots:_vm._u([{key:"item.empresaPorDefectoBool",fn:function(ref){
var item = ref.item;
return [(item.empresaPorDefectoBool)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.checkIcon)+" ")]):_vm._e()]}},{key:"item.sucursal",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(item.sucursal))])]}}],null,true)})],1),_c('v-card-actions',{staticClass:"pr-4"},[_c('v-spacer'),_c('v-btn',{staticClass:"to-right fontsize",attrs:{"disabled":_vm.itemsProcessToSee.length == 0,"color":"primary","outlined":""},on:{"click":_vm.exportDataToSee}},[_vm._v(" Exportar detalle ")]),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){(_vm.openModalSeeProcess = false), (_vm.itemsProcessToSee = [])}}},[_vm._v(" Cerrar ")])],1)],1)],1)],1):_vm._e()],1),_c('v-col',{staticClass:"to-right pt-0 px-0",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":function($event){return _vm.toggleHelp()},"toggleIcon":function($event){return _vm.toggleIcon()}}})],1),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"maxWidth":'35%',"confirmButtonText":'Anular',"openDelete":_vm.showAnularModal,"isLoading":_vm.isLoadingAnular},on:{"update:openDelete":function($event){_vm.showAnularModal=$event},"update:open-delete":function($event){_vm.showAnularModal=$event},"onDeleteItem":function($event){return _vm.confirmAnularProceso()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }